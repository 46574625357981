import { useSelector } from 'react-redux';
import ChoosePolicyItem from './ChoosePolicyItem';
import { StyledChoosePolicyContainer } from './styles';
import { IChoosePolicyProps } from './types';
import { selectAddSelectedPolicyDetails } from '../../../../redux/slices/singleAddSlice';
import { useEffect, useMemo } from 'react';
import { checkIfPolicySelectionDisabled, getUniquePolicyChecks } from './utils';
import Loader from '../../../atoms/Spinner';
import { Tooltip } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';

const ChoosePolicy: React.FC<IChoosePolicyProps> = ({
  policiesList,
  isLoading,
  isOldEmployee,
}) => {
  const trackTask = useSegment('track');
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);

  const { uniquePolices, selectedGMCPolicyId, selectedParentalPolicyId } =
    useMemo(() => getUniquePolicyChecks(selectedPolicies), [selectedPolicies]);

  useEffect(() => {
    if (isOldEmployee) 
      trackTask(trackTaskEvent('Load_Policy_Selection_Modal_Dependant_Single_Add'));
    else trackTask(trackTaskEvent('Load_Policy_Selection_Modal_Single_Add'));
  }, []);

  return (
    <StyledChoosePolicyContainer>
      {isLoading ? (
        <Loader />
      ) : (
        policiesList.map((policy, index) => {
          const isDisabled = checkIfPolicySelectionDisabled(
            policy,
            selectedGMCPolicyId,
            selectedParentalPolicyId,
            uniquePolices,
            selectedPolicies,
          );
          const key = `${policy.id}-${isDisabled}-${!!selectedPolicies[
            policy.id
          ]}`;
          const component = (
            <WithThemeProvider key={key}>
              <ChoosePolicyItem policy={policy} isDisabled={isDisabled} isOldEmployee/>
            </WithThemeProvider>
          );
          return isDisabled ? (
            <Tooltip
              key={`${key}-tooltip`}
              text={`Employee cannot be enrolled in multiple ${policy.policyType} policies. Please deselect the other ${policy.policyType} policy to proceed with this selection.`}
              type="informative"
              beak="top-left"
            >
              {component}
            </Tooltip>
          ) : (
            component
          );
        })
      )}
    </StyledChoosePolicyContainer>
  );
};

export default ChoosePolicy;
