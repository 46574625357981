import { Stepper } from '@loophealth/loop-ui-web-library';
import SubmitEndo from '../../Bulk/SubmitEndo';
import {
  StyledReviewCostScreen,
  StyledSingleAddStepperWrapper,
  StyledSubmitEndoWrapper,
} from './styles';
import { IReviewCostScreen } from './types';
import { LOADING_CONSTANTS, STEPPER_DATA } from '../../Bulk/constants';
import FullPageLoader from '../../../atoms/Loader';
import { useEffect } from 'react';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';

const ReviewCostScreen: React.FC<IReviewCostScreen> = ({ endoCostDetails, isOldEmployee }) => {
  const trackTask = useSegment('track');
  useEffect(() => {
    if(isOldEmployee)
      trackTask(trackTaskEvent('Load_Review_Additional_Cost_Modal_Dependant_Single_Add'));
    else
      trackTask(trackTaskEvent('Load_Review_Additional_Cost_Modal_Single_Add'));
  }, [])
  return (
    <StyledReviewCostScreen>
      <StyledSingleAddStepperWrapper>
        <Stepper
          steps={STEPPER_DATA.SINGLE_ADD}
          currentStep={2}
          variant="locked"
          onStepperClicked={() => {}}
        />
      </StyledSingleAddStepperWrapper>
      <StyledSubmitEndoWrapper>
        {endoCostDetails.isLoading ? (
          <FullPageLoader {...LOADING_CONSTANTS.CHECKING_ADD_ENDO_COST} />
        ) : (
          <SubmitEndo
            mode="ADD"
            isLoading={endoCostDetails.isLoading}
            endoCostList={endoCostDetails.endoCostList}
            totalCost={endoCostDetails.totalCost}
            totalLives={endoCostDetails.totalLives}
          />
        )}
      </StyledSubmitEndoWrapper>
    </StyledReviewCostScreen>
  );
};

export default ReviewCostScreen;
