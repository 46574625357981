import { IRelationship } from '../../../components/containers/Bulk/types';
import { IUserPolicy } from '../../../components/containers/TopNavigation/types';
import { EmployeeDetails } from '../../../components/pages/EmployeeDetails/types';
import { SINGLE_ADD_DEPENDENT_STEPS } from '../../../components/pages/Endorsements/SingleAddDependents/constants';
import { SINGLE_ADD_STEPS } from '../../../components/pages/Endorsements/SingleAddMembers/constants';

export type IGender = 'male' | 'female' | '';

export enum ADD_FORM_SUBMISSION_STATUS {
  IDLE,
  PRIMARY,
  SECONDARY,
}

export interface ISingelAddSliceState {
  currentStep: SINGLE_ADD_STEPS | SINGLE_ADD_DEPENDENT_STEPS;
  lastStep: SINGLE_ADD_STEPS | SINGLE_ADD_DEPENDENT_STEPS;
  selectedPolicyDetails: Record<string, ISingleAddSelectedPolicy>;
  employee: IEmployeeFormData;
  dependants: IDependant[];
  employeeErrors: IEmployeeErrors;
  employeeIdErrors: IEmployeeIdErrors | null;
  dependantErrors: IDependantErrors;
  selectedDependant: string | null;
  selectedEmployee: ISelectedEmployee | null;
  deletingDependantId: string | null;
  formSubmissionStatus: ADD_FORM_SUBMISSION_STATUS;
  exitModalVisible: boolean;
  proceedWithoutDeps: boolean;
}

export interface ISingleAddSelectedPolicy {
  policyId: string;
  slabId: string;
  policyType: string;
  isParentalPolicy: boolean;
  isOPDPolicy: boolean;
  sumInsured: number;
  grade?: string;
  sumInsuredStructure: string;
  slabFamilyDefinition: string;
}

export interface IEmployeeFormData {
  _id: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: Date | null;
  policyStartDate: Date | null;
  email: string;
  mobile: string;
  relationship?: IRelationship;
  ctc: string;
}

export interface IEmployeeErrors {
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  dob?: string;
  policyStartDate?: string;
  email?: string;
  mobile?: string;
  ctc?: string;
}

export interface IEmployeeIdErrors {
  erroredEmployeeId: string;
  employeeAlreadyPresent: boolean;
  employeeAlreadyRegisteredPolicies: string[];
  employeePartiallyPresent: boolean;
  name: string;
}

export interface IDependantErrors {
  employeeId: string;
  relationship: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  mobile: string;
  email: string;
  policyStartDate: string;
  ctc?: string;
  dateOfMarriage?: string;
  kycDocumentURLs?: string;
  global?: string;
  joiningDate?: string;
}

export interface IDependant {
  _id?: string;
  userId?: string;
  employeeId?: string;
  policyStartDate?: Date | null;
  relationship?: IRelationship;
  dob: Date | null;
  gender: IGender;
  email: string;
  mobile: string;
  firstName: string;
  lastName: string;
  dateOfMarriage?: Date | null;
  kycDocumentURLs?: string;
  kycDocumentSize?: number;
  ctc?: string;
  uhid?: string;
  insuranceStatus?: string;
  global?: string;
  isAlreadyEnrolled?: boolean;
}

export interface ISelectedEmployee extends IEmployeeFormData {
  userId: string;
  name: string;
  policyWiseDeps: EmployeeDetails[];
  userPolicies: IUserPolicy[];
  isEditable?: boolean;
  isDraftMember?: boolean;
  deletionInProgress?: boolean;
  isAlreadyEnrolled?: boolean;
}
