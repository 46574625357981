import LoopApiService from '../../../../adaptars/LoopApiService';
import { parseResponse } from '../../../../utils/common/Utilities';
import { trackTaskEvent } from '../../../../utils/segment/utils';
import { IButtonState } from '../../../containers/Bulk/types';
import { getDeleteValidationSummaryData } from '../../../containers/ValidationsScreen/utils';
import {
  IBulkDeleteData,
  IDeleteResponseInvalidEntity,
  IRejectedDeleteEntity,
} from './types';

export const getButtonStates = (
  currentStep: number,
  selectedLength: number,
  finalCorrectData: IBulkDeleteData[],
  rejectedEntries: IRejectedDeleteEntity[],
): IButtonState => {
  switch (currentStep) {
    case 1:
      return {
        isNextEnabled: !!selectedLength,
        isBackEnabled: false,
        buttonText: 'Upload & Proceed',
        backButtonText: '',
      };
    case 2:
      return {
        isNextEnabled: true,
        isBackEnabled: !!finalCorrectData.length,
        buttonText: finalCorrectData.length
          ? `View Refund of ${finalCorrectData.length} Correct Lives `
          : 'Re-Upload all Lives',
        backButtonText: 'Re-Upload all Lives',
      };
    case 3:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${finalCorrectData.length} Lives`,
        backButtonText:
          rejectedEntries.length > 0 ? 'Review Again' : 'Re-Upload all Lives',
        animation: 'infinite',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
      };
  }
};

export const validateDeleteData = async (
  memberList: IBulkDeleteData[],
  companyId: string,
): Promise<{
  correctLives: IBulkDeleteData[];
  rejectedLives: IRejectedDeleteEntity[];
}> => {
  const payload = {
    companyId,
    memberList,
    enableEndorsements: true,
    returnMultipleErrors: true,
  };

  const [error, response] = await parseResponse(
    LoopApiService.validateBulkDeletionData(payload),
  );
  if (error) throw error;

  const correctLives: IBulkDeleteData[] = [];
  const rejectedLives: IRejectedDeleteEntity[] = [];
  const rejectedData = (response.data?.invalidMembers ||
    []) as IDeleteResponseInvalidEntity[];
  memberList.forEach((member) => {
    const rejectedMember = rejectedData.find(
      (rejectedMember) => rejectedMember.employeeId === member.employeeId,
    );
    if (rejectedMember) {
      rejectedLives.push({ ...member, error: rejectedMember.error });
    } else {
      correctLives.push(member);
    }
  });

  return {
    correctLives,
    rejectedLives,
  };
};

export const trackDeleteValidationSummary = (
  uploadedData: IBulkDeleteData[],
  rejectedEntries: IRejectedDeleteEntity[],
  trackTask: ({
    name,
    properties,
  }: {
    name: string;
    properties?: Record<string, unknown>;
  }) => void,
  location: string,
) => {
  const summaryData = getDeleteValidationSummaryData(
    uploadedData,
    rejectedEntries,
  );
  trackTask(
    trackTaskEvent(`Delete_Validation_Summary`, location, '', summaryData),
  );
};
