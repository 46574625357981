import { LoopButton, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ClipboardTwoIcon,
  ScoreCardImage,
  UserUsingPhone,
  WarningIcon,
} from '../../../../assets/images';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
import useEndoSummaryData from '../hooks/useEndoSummaryData';
import {
  StyledLeftSection,
  StyledLeftSectionDescription,
  StyledLeftSectionDescriptionList,
  StyledLeftSectionDescriptionListItem,
  StyledLeftSectionDescriptionListItemTag,
  StyledLeftSectionDescriptionTitle,
  StyledLeftSectionLowCD,
  StyledLeftSectionLowCDImage,
  StyledLeftSectionTitle,
  StyledLeftSectionTitleImage,
  StyledRightSection,
  StyledRightSectionBottom,
  StyledRightSectionTitle,
  StyledRightSectionTitleIcon,
  StyledRightSectionTnCList,
  StyledRightSectionTnCListItem,
  StyledRightSectionTnCListItemBullet,
  StyledRightSectionTop,
  StyledRightSectionTopImage,
  StyledRightSectionTopRadial,
  StyledWrapper,
  StyledWrapperBody,
  StyledWrapperFooter,
  StyledWrapperFooterButton,
} from './styles';
import { IEndoSuccessSummary } from './types';
import { BulletTypeOne } from '../../../../assets/icons/BulletTypeOne';
import { groupByPolicyType } from '../utils';
import { WarningAlertIcon } from '../../../../assets/icons';
import { resetSingleAddState } from '../../../../redux/slices/singleAddSlice';
import { useDispatch } from 'react-redux';

const EndoSuccessSummary: React.FC<IEndoSuccessSummary> = ({
  endoCostList,
  submittedData,
  mode,
  isOldEmployeeFlow
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const toast = Toast.useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const submittedLives = useEndoSummaryData(mode, submittedData);

  const groupedPolicyData = useMemo(() => {
    return endoCostList ? groupByPolicyType(endoCostList) : [];
  }, [endoCostList]);

  const isAnyCDLow = useMemo(() => {
    return groupedPolicyData.some((policyData) => policyData.isCDLow);
  }, [groupedPolicyData]);

  const SummaryList = useMemo(() => {
    if (['DELETE', 'SINGLE_DELETE'].includes(mode)) {
      return (
        <StyledLeftSectionDescriptionListItem>
          <BulletTypeOne />
          <Typography variant="medium" color="secondary">
            Deletion request of lives from all policies received
          </Typography>
        </StyledLeftSectionDescriptionListItem>
      );
    } else if (['ADD', 'SINGLE_ADD'].includes(mode)) {
      return groupedPolicyData.map((policyData, index) => (
        <StyledLeftSectionDescriptionListItem key={index}>
          <BulletTypeOne />
          <Typography variant="medium" color="secondary">
            Addition request of {policyData.lives}
            {policyData.lives > 1 ? ' lives' : ' live'} to{' '}
            {policyData.policyType} Policy received
          </Typography>
          {policyData.isCDLow && (
            <StyledLeftSectionDescriptionListItemTag>
              <Typography variant="extraSmall" color="error">
                Low CD
              </Typography>
            </StyledLeftSectionDescriptionListItemTag>
          )}
        </StyledLeftSectionDescriptionListItem>
      ));
    } else if (mode === 'EDIT') {
      return groupedPolicyData.map((policyData, index) => (
        <StyledLeftSectionDescriptionListItem key={index}>
          <BulletTypeOne />
          <Typography variant="medium" color="secondary">
            Edit request of {policyData.lives}
            {policyData.lives > 1 ? ' lives' : ' live'} in{' '}
            {policyData.policyType} Policy received
          </Typography>
          {policyData.isCDLow && (
            <StyledLeftSectionDescriptionListItemTag>
              <Typography variant="extraSmall" color="error">
                Low CD
              </Typography>
            </StyledLeftSectionDescriptionListItemTag>
          )}
        </StyledLeftSectionDescriptionListItem>
      ));
    }
  }, [mode]);

  const handleSubmitClick = () => {
    setTimeout(() => {
      toast?.success(
        'Submission complete! You can now track the progress of your endorsements',
        '',
        {
          closeOnClick: false,
          variant: 'light',
        },
      );
      trackClick(
        trackClickEvent(
          'OkayGotIt_track',
          location?.pathname,
          extractPathName(location?.pathname),
          { submittedLives, mode },
        ),
      );
    }, 1500);
    history.replace('/endorsements', { endoSubmitted: true });
    if (mode === 'SINGLE_ADD') dispatch(resetSingleAddState());
  };

  useEffect(() => {
    if(mode === 'SINGLE_DELETE')
      trackClick(trackClickEvent('Endorsement_Submit_Success_Screen_single_del'));
    if(mode === 'SINGLE_ADD') {
      if(isOldEmployeeFlow)
        trackClick(trackClickEvent('Load_Endorsement_Submit_Success_Screen_Dependant_Single_Add'));
      else
        trackClick(trackClickEvent('Load_Endorsement_Submit_Success_Screen_Single_Add'));
    }
  }, [])

  return (
    <StyledWrapper>
      <StyledWrapperBody>
        <StyledLeftSection>
          <StyledLeftSectionTitle>
            <StyledLeftSectionTitleImage
              src={ScoreCardImage}
              alt="Score Card"
            />
            <Typography variant="title2" color="emerald" weight="medium">
              You’ve successfully submitted your data to Loop!
            </Typography>
          </StyledLeftSectionTitle>
          <StyledLeftSectionDescription>
            <StyledLeftSectionDescriptionTitle>
              <Typography variant="medium" color="primary">
                Track your endorsement on the dashboard. Data may take about 20
                minutes to show.
              </Typography>
            </StyledLeftSectionDescriptionTitle>
            {isAnyCDLow && (
              <StyledLeftSectionLowCD>
                <StyledLeftSectionLowCDImage>
                  <WarningAlertIcon strokeWidth="1.2" />
                </StyledLeftSectionLowCDImage>
                <Typography variant="small" color="error" weight="bold">
                  Note:
                </Typography>
                <Typography variant="small" color="error">
                  Your data will be submitted to the insurer once there is
                  sufficient CD balance
                </Typography>
              </StyledLeftSectionLowCD>
            )}
            <StyledLeftSectionDescriptionList>
              {SummaryList}
            </StyledLeftSectionDescriptionList>
          </StyledLeftSectionDescription>
        </StyledLeftSection>
        <StyledRightSection>
          {['ADD', 'SINGLE_ADD'].includes(mode) && (
            <StyledRightSectionTop>
              <Typography variant="large" color="primary" weight="semiBold">
                Loop App Access Enabled!
              </Typography>
              <Typography variant="extraSmall" color="secondary">
                Employees added in this endorsement have immediate access to the
                Loop App!
              </Typography>
              <StyledRightSectionTopRadial></StyledRightSectionTopRadial>

              <StyledRightSectionTopImage
                src={UserUsingPhone}
                alt="user-using-phone"
              />
            </StyledRightSectionTop>
          )}
          <StyledRightSectionBottom>
            <StyledRightSectionTitle>
              <StyledRightSectionTitleIcon src={ClipboardTwoIcon} />
              <Typography variant="medium" color="primary" weight="semiBold">
                What’s Next?
              </Typography>
            </StyledRightSectionTitle>
            <StyledRightSectionTnCList>
              <StyledRightSectionTnCListItem>
                <StyledRightSectionTnCListItemBullet></StyledRightSectionTnCListItemBullet>
                <Typography variant="small" color="secondary">
                  Data will be reviewed by Loop to ensure compliance.
                </Typography>
              </StyledRightSectionTnCListItem>
              <StyledRightSectionTnCListItem>
                <StyledRightSectionTnCListItemBullet></StyledRightSectionTnCListItemBullet>
                <Typography variant="small" color="secondary">
                  Subject to review, the data will be sent to the insurer.
                </Typography>
              </StyledRightSectionTnCListItem>
              <StyledRightSectionTnCListItem>
                <StyledRightSectionTnCListItemBullet></StyledRightSectionTnCListItemBullet>
                <Typography variant="small" color="secondary">
                  Loop & insurer, can reject data that fails to meet policy
                  conditions.
                </Typography>
              </StyledRightSectionTnCListItem>
            </StyledRightSectionTnCList>
          </StyledRightSectionBottom>
        </StyledRightSection>
      </StyledWrapperBody>
      <StyledWrapperFooter>
        <StyledWrapperFooterButton>
          <LoopButton variant="filled" onClick={handleSubmitClick}>
            Okay! Got it
          </LoopButton>
        </StyledWrapperFooterButton>
      </StyledWrapperFooter>
    </StyledWrapper>
  );
};
export default EndoSuccessSummary;
