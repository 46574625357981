import { IDependant } from '../../../../redux/slices/singleAddSlice/types';
import { trackClickEvent } from '../../../../utils/segment/utils';
import { generateLabels } from '../AddDependantModal/utils';
import { IMappings } from '../mapping';

export const generateDependantsData = ({
  selectedFamilyDefinition,
  existingDependants,
  employee,
  initialDependantDetails,
}: {
  selectedFamilyDefinition: IMappings;
  existingDependants: IDependant[];
  employee: IDependant;
  initialDependantDetails: IDependant;
}) => {
  const dependantsData: IDependant[] = [employee];
  const existingSpouse = existingDependants.find(
    (dependant) => dependant.relationship === 'spouse',
  );
  const existingChildrens = existingDependants.filter(
    (dependant) => dependant.relationship === 'child',
  );
  const existingParents = existingDependants.filter(
    (dependant) => dependant.relationship === 'parent',
  );
  const existingParentsInLaw = existingDependants.filter(
    (dependant) => dependant.relationship === 'parent-in-law',
  );
  selectedFamilyDefinition.dependents?.forEach((family) => {
    if (family.relation === 'spouse') {
      const spouseDetails = existingSpouse
        ? {
            ...existingSpouse,
          }
        : {
            ...initialDependantDetails,
          };
      dependantsData.push({
        ...spouseDetails,
        relationship: 'spouse',
        _id: 'spouse',
      });
    } else if (family.relation === 'child') {
      for (let i = 1; i <= family.number; i++) {
        if (existingChildrens.length) {
          dependantsData.push({
            ...existingChildrens[0],
            relationship: 'child',
            _id: `c${i}`,
          });
          existingChildrens.shift();
        } else {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'child',
            _id: `c${i}`,
          });
        }
      }
    } else if (family.relation === 'parent') {
      // todo @sourav handle the edge case when data is present for parent-in-law then parent-in-law
      // radio button should be selected
      for (let i = 1; i <= family.number; i++) {
        if (existingParents.length) {
          dependantsData.push({
            ...existingParents[0],
            relationship: 'parent',
            _id: `p${i}`,
          });
          existingParents.shift();
        } else {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent',
            _id: `p${i}`,
          });
        }
      }
    } else if (family.relation === 'parent-in-law') {
      for (let i = 1; i <= family.number; i++) {
        if (existingParentsInLaw.length) {
          dependantsData.push({
            ...existingParentsInLaw[0],
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
          existingParentsInLaw.shift();
        } else {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
        }
      }
    } else if (
      ['parent/parent-in-law', 'parent|parent-in-law'].includes(family.relation)
    ) {
      for (let i = 1; i <= family.number; i++) {
        if (existingParents.length) {
          dependantsData.push({
            ...existingParents[0],
            relationship: 'parent',
            _id: `p${i}`,
          });
          existingParents.shift();
        } else {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent',
            _id: `p${i}`,
          });
        }
        if (existingParentsInLaw.length) {
          dependantsData.push({
            ...existingParentsInLaw[0],
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
          existingParentsInLaw.shift();
        } else {
          dependantsData.push({
            ...initialDependantDetails,
            relationship: 'parent-in-law',
            _id: `pil${i}`,
          });
        }
      }
    }
  });
  return dependantsData;
};

export const trackDependentOpeningModal = (
  relationship: string,
  isOldEmployee?: boolean,
) => {
  const text = generateLabels(relationship).cardButtonText.replace(' ', '_');
  return trackClickEvent(
    isOldEmployee
      ? `${text}_Add_Dependant_Modal_Dependant_Single_Add`
      : `${text}_Add_Dependant_Modal_Single_Add`,
  );
};
