import {
  Alerts,
  Chip,
  Form,
  LoopButton,
  Modal,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoErrorIcon, InfoLightIcon } from '../../../../assets/images';
import {
  selectAddEmployeeDetails,
  selectAddEmployeeErrors,
  selectAddEmployeeIdErrors,
  selectAddSelectedPolicyDetails,
  setAddEmployeeDetails,
  setAddEmployeeErrors,
  setAddEmployeeIdErrors,
  setAddSelectedPolicies,
} from '../../../../redux/slices/singleAddSlice';
import {
  IEmployeeErrors,
  IEmployeeFormData,
} from '../../../../redux/slices/singleAddSlice/types';
import { allEmployeeDetailFields } from '../../../pages/Endorsements/SingleAddMembers/constants';
import {
  checkIfCtcRequired,
  getEmpIdErrorAlertText,
} from '../../../pages/Endorsements/SingleAddMembers/utils';
import { GENDER_DROPDOWN_ITEMS } from '../../Bulk/constants';
import {
  StyledAlertWrapper,
  StyledChipContainer,
  StyledDeselectModalCta,
  StyledDeselectModalText,
  StyledDeselectModalWrapper,
  StyledEmployeeContainer,
  StyledEmployeeExtraErrors,
  StyledEmployeeFormContainer,
  StyledEmployeeFormItem,
} from './styles';
import { StyledFitContentWrapper } from '../../EmpPolicies/styles';
import MobileNumberInput from '../../../atoms/MobileNumberInput';
import { validateEmail } from '../../../../utils/common/Utilities';
import moment from 'moment';
import { regexValidations } from '../../../../utils/hooks/useFormInput';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';

const AddEmployeeDetails = () => {
  const trackTask = useSegment('track');
  const dispatch = useDispatch();
  const toast = Toast.useToast();
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);

  const formData = useSelector(selectAddEmployeeDetails);
  const errorData = useSelector(selectAddEmployeeErrors);
  const employeeIdError = useSelector(selectAddEmployeeIdErrors);

  const [deselectModalVisible, setDeselectModalVisible] = useState(false);

  const setFormData = (data: IEmployeeFormData) =>
    dispatch(setAddEmployeeDetails(data));
  const setErrorData = (errors: IEmployeeErrors) =>
    dispatch(setAddEmployeeErrors(errors));

  const extraErrors = useMemo(() => {
    const errors = structuredClone(errorData);
    allEmployeeDetailFields.forEach((field) => {
      delete errors[field];
    });
    return Object.values(errors).filter(Boolean);
  }, [errorData]);

  const employeeIdErrorText = useMemo(
    () => getEmpIdErrorAlertText(employeeIdError, selectedPolicies),
    [employeeIdError, selectedPolicies],
  );

  const isCtcRequired = useMemo(
    () => checkIfCtcRequired(selectedPolicies),
    [selectedPolicies],
  );

  const minDojAllowed = useMemo(
    () => moment().subtract(44, 'days').startOf('day').toDate(),
    [],
  );

  const clearErrorForId = (id: string) => {
    setErrorData({ ...errorData, [id]: '' });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    clearErrorForId(e.target.id);
  };
  const handleGenderChange = (gender: string) => {
    setFormData({
      ...formData,
      gender,
    });
    clearErrorForId('gender');
  };
  const handleDateChange = (key: string, value: Date) => {
    setFormData({
      ...formData,
      [key]: value,
    });
    clearErrorForId(key);
  };
  const handleMobileChange = (value: string) => {
    setFormData({ ...formData, mobile: value });
    clearErrorForId('mobile');
  };

  const setErrorForId = (id: string, error: string) => {
    setErrorData({ ...errorData, [id]: error });
  };

  const validateEmailFormat = () => {
    const v = formData.email;
    setErrorForId('email', '');
    if (!v) return;
    if (!validateEmail(v)) setErrorForId('email', 'The email ID is invalid.');
  };

  const validateNameFormat = (field: keyof IEmployeeFormData) => {
    const v = formData[field] as string;
    const required = field === 'firstName';
    if (!required && !v) return;
    const name = v?.trim();
    if (!name || !name.length) {
      setErrorForId(field, 'Name is required');
    } else if (!regexValidations.fname.test(name)) {
      setErrorForId(
        field,
        'Name should not contain any special characters or numbers. Please provide the correct name.',
      );
    }
  };

  const alertDescription = employeeIdErrorText ? (
    <StyledAlertWrapper>
      <Typography variant="small" weight="semiBold" color="error">
        {employeeIdErrorText}
      </Typography>
      {employeeIdError?.employeePartiallyPresent && (
        <StyledFitContentWrapper>
          <LoopButton
            size="small"
            variant="error"
            iconSrc={undefined}
            onClick={() => setDeselectModalVisible(true)}
          >
            Deselect policies
          </LoopButton>
        </StyledFitContentWrapper>
      )}
    </StyledAlertWrapper>
  ) : null;

  const handleDeselectPolicies = () => {
    if (!employeeIdError?.employeeAlreadyRegisteredPolicies) return;
    setDeselectModalVisible(false);
    const policyNames = employeeIdError.employeeAlreadyRegisteredPolicies
      ?.map((policyId) => selectedPolicies[policyId].policyType)
      .join(', ')
      .replace(/,([^,]*)$/, ' and$1');
    const policies = structuredClone(selectedPolicies);
    employeeIdError.employeeAlreadyRegisteredPolicies.forEach((policyId) => {
      delete policies[policyId];
    });
    dispatch(setAddSelectedPolicies(policies));
    dispatch(setAddEmployeeIdErrors(null));
    dispatch(setAddEmployeeErrors({ ...errorData, employeeId: '' }));
    toast?.error(
      `${policyNames} policies have been deselected for the employee`,
      '',
      {
        variant: 'dark',
        icon: InfoLightIcon,
      },
    );
  };

  useEffect(() => {
    trackTask(trackTaskEvent('Load_Employee_Detail_Addition_Employee_Add_Modal_Single_Add'));
  }, [])

  return (
    <StyledEmployeeContainer>
      {alertDescription ? (
        <Alerts.StatusAlert
          variant="over"
          text={alertDescription}
          iconSrc={InfoErrorIcon}
        />
      ) : null}
      {extraErrors.length ? (
        <Typography variant="small" weight="semiBold" color="error">
          <Alerts.StatusAlert
            alignItems="center"
            variant="over"
            iconSrc={InfoErrorIcon}
            text={
              <StyledEmployeeExtraErrors>
                {extraErrors.map((error, index) => (
                  <Typography key={error} variant="small" color="error">
                    {extraErrors.length > 1 ? `${index + 1}. ` : ''}
                    {error}
                  </Typography>
                ))}
              </StyledEmployeeExtraErrors>
            }
          />
        </Typography>
      ) : null}

      <Typography variant="large">Employee Information</Typography>
      <StyledEmployeeFormContainer>
        <StyledEmployeeFormItem $errored={!!errorData.employeeId}>
          <Form.FormLabel id="employeeId" label="Employee ID" required />
          <Form.Input
            type="text"
            id="employeeId"
            value={formData.employeeId}
            error={errorData.employeeId}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem
          $errored={!!errorData.firstName}
          onBlur={() => validateNameFormat('firstName')}
        >
          <Form.FormLabel id="firstName" label="First Name" required />
          <Form.Input
            type="text"
            id="firstName"
            error={errorData.firstName}
            value={formData.firstName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem
          $errored={!!errorData.lastName}
          onBlur={() => validateNameFormat('lastName')}
        >
          <Form.FormLabel id="firstName" label="Last Name" />
          <Form.Input
            type="text"
            id="lastName"
            error={errorData.lastName}
            value={formData.lastName}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.gender}>
          <Form.FormLabel id="gender" label="Gender" required />
          <StyledChipContainer>
            {GENDER_DROPDOWN_ITEMS.map((item) => (
              <Chip
                label={item.name}
                key={item.value}
                isActive={formData.gender === item.value}
                onClick={() => handleGenderChange(item.value)}
              />
            ))}
          </StyledChipContainer>
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.dob}>
          <Form.FormLabel id="dateOfBirth" label="Date of Birth" required />
          <Form.DatePicker
            placeholder="Enter Date (DD/MM/YYYY)"
            value={formData.dob}
            onChange={(value) => handleDateChange('dob', value)}
            error={errorData.dob}
            displayFormat="DD/MMM/YYYY"
            iconOrder="right"
            maxDate={new Date()}
            typable
            fontSize="small"
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.policyStartDate}>
          <Form.FormLabel id="dateOfJoining" label="Date of Joining" required />
          <Form.DatePicker
            placeholder="Enter Date (DD/MM/YYYY)"
            value={formData.policyStartDate}
            onChange={(value) => handleDateChange('policyStartDate', value)}
            error={errorData.policyStartDate}
            displayFormat="DD/MMM/YYYY"
            iconOrder="right"
            maxDate={new Date()}
            minDate={minDojAllowed}
            fontSize="small"
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem
          $errored={!!errorData.email}
          onBlur={validateEmailFormat}
        >
          <Form.FormLabel id="email" label="Work Email" />
          <Form.Input
            type="email"
            id="email"
            error={errorData.email}
            value={formData.email}
            onChange={handleOnChange}
          />
        </StyledEmployeeFormItem>
        <StyledEmployeeFormItem $errored={!!errorData.mobile}>
          <Form.FormLabel id="mobile" label="Mobile Number" />
          <MobileNumberInput
            error={errorData.mobile}
            value={formData.mobile}
            onChange={handleMobileChange}
            placeholder="Enter mobile number"
            onError={(error) => setErrorForId('mobile', error)}
          />
        </StyledEmployeeFormItem>
        {isCtcRequired ? (
          <StyledEmployeeFormItem $errored={!!errorData.ctc}>
            <Form.FormLabel id="ctc" label="Annual CTC (₹)" required />
            <Form.Input
              type="number"
              id="ctc"
              error={errorData.ctc}
              value={formData.ctc}
              onChange={handleOnChange}
            />
          </StyledEmployeeFormItem>
        ) : (
          <StyledEmployeeFormItem />
        )}
      </StyledEmployeeFormContainer>
      <Modal
        isVisible={deselectModalVisible}
        setIsVisible={setDeselectModalVisible}
      >
        <StyledDeselectModalWrapper>
          <StyledDeselectModalText>
            <Typography variant="large" weight="semiBold">
              Deselect the policies?
            </Typography>
            <Typography variant="small">
              You are about to deselect policies the employee is already insured
              under
            </Typography>
          </StyledDeselectModalText>
          <StyledDeselectModalCta>
            <LoopButton variant="error" onClick={handleDeselectPolicies}>
              Yes, Deselect
            </LoopButton>
            <LoopButton
              variant="outline"
              onClick={() => setDeselectModalVisible(false)}
            >
              No, Go back
            </LoopButton>
          </StyledDeselectModalCta>
        </StyledDeselectModalWrapper>
      </Modal>
    </StyledEmployeeContainer>
  );
};

export default AddEmployeeDetails;
