import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AnnounceIcon,
  CrossOutlineDarkIcon,
  MultiTrashIcon,
  SingleTrashIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { capitalizeFirstLetter } from '../../../utils/common/Utilities';
import useSegment from '../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackTaskEvent,
} from '../../../utils/segment/utils';
import { extractDate } from '../../pages/Endorsements/ListingPage/utils';
import { IEndoAddEditDeleteModalProps } from '../EndoAddEditDeleteModal/types';
import {
  StyledCrossIcon,
  StyledEndoActionContainer,
  StyledEndoActionImage,
  StyledEndoActions,
  StyledHeader,
  StyledNotificationContainer,
} from './styles';

const EndoDeleteOptionsModal: FC<IEndoAddEditDeleteModalProps> = ({
  isVisible,
  setIsVisible,
  dateText,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();

  const handleCrossIconClick = () => {
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        `X_Delete_Options_Modal_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onSingleDeleteClick = () => {
    trackClick(
      trackClickEvent(
        `Single_Delete_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_single_del`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/single-delete');
  };

  const onBulkDeleteClick = () => {
    trackClick(
      trackClickEvent(
        `Bulk_Delete_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_del`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/bulk-delete');
  };

  useEffect(() => {
    if (isVisible)
      trackTask(
        trackTaskEvent(
          `Load_Delete_Options_Modal_${capitalizeFirstLetter(
            location.pathname.substring(1),
          )}`,
          location.pathname,
        ),
      );
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isModalClosable={false}
    >
      <WithThemeProvider>
        <StyledHeader>
          <Typography variant="large">Choose an option</Typography>
          <StyledCrossIcon
            src={CrossOutlineDarkIcon}
            onClick={handleCrossIconClick}
          />
        </StyledHeader>
        <StyledNotificationContainer>
          <img src={AnnounceIcon} alt="alert" />
          {dateText ? (
            <Typography variant="small" color="secondary">
              While you can submit your data with us, Loop will share the data
              with the insurer on the{' '}
              <Typography variant="small" color="emerald" weight="bold">
                {extractDate(dateText)} of every month!
              </Typography>
            </Typography>
          ) : (
            <Typography variant={'small'} color={'secondary'}>
              While you can submit your data with us, Loop will share your data
              with the insurer once an endorsement date is fixed. Please reach
              out to CSM.
            </Typography>
          )}
        </StyledNotificationContainer>
        <StyledEndoActionContainer>
          <StyledEndoActions onClick={onSingleDeleteClick}>
            <StyledEndoActionImage src={SingleTrashIcon} />
            <Typography variant="large" weight="medium">
              Delete a single employee
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Delete a single employee and their dependants from the dashboard
            </Typography>
          </StyledEndoActions>
          <StyledEndoActions onClick={onBulkDeleteClick}>
            <StyledEndoActionImage src={MultiTrashIcon} />
            <Typography variant="large" weight="medium">
              Bulk delete lives
            </Typography>
            <Typography variant="small" weight="medium" color="secondary">
              Delete multiple employees and their dependants using excel
            </Typography>
          </StyledEndoActions>
        </StyledEndoActionContainer>
      </WithThemeProvider>
    </Modal>
  );
};
export default EndoDeleteOptionsModal;
