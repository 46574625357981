import styled from 'styled-components';

export const StyledSubmitButtonPopupWrapper = styled.div`
  position: relative;
  & > button::after {
    display: none;
  }
`;

export const StyledSubmitButtonPopup = styled.div`
  position: absolute;
  bottom: calc(100% + 4px);
  right: 0px;
  width: 260px;
  padding-bottom: 12px;
`;

export const StyledSubmitButtonPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  padding: 20px;
  gap: 4px;
  background-color: ${(p) => p.theme.colors.coverYellow};
`

export const StyledSubmitButtonHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSubmitButtonPopupBeak = styled.div`
  position: absolute;
  top: 100%;
  right: 32px;
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid ${(p) => p.theme.colors.coverYellow};
`;

