import { StyledDependantContainer } from './styles';
import AddDependantModal from '../AddDependantModal';
import AddEditSelfAndSpouseSection from './AddEditSelfAndSpouseSection';
import AddEditChildsSection from './AddEditChildsSection';
import AddEditParentsSection from './AddEditParentsSection';
import { useDependantsHelper } from './useDependantsHelper';
import { useEffect } from 'react';
import EndoAddDeleteDependantModal from '../../Bulk/EndoAddDeleteDependantModal';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';
import { IAddDependantDetails } from './types';

const AddDependantDetails: React.FC<IAddDependantDetails> = ({
  isOldEmployee,
}) => {
  const trackTask = useSegment('track');
  const {
    generateInitialDependantsData,
    activeDependants,
    selectedPolicyDetails,
  } = useDependantsHelper();

  useEffect(() => {
    if (activeDependants.length < 2) {
      generateInitialDependantsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicyDetails]);

  useEffect(() => {
    if(isOldEmployee)
      trackTask(trackTaskEvent('Load_Add_Dependant_Modal_Dependant_Single_Add'));
    else trackTask(trackTaskEvent('Load_Add_Dependant_Modal_Single_Add'));
  }, []);
  return (
    <StyledDependantContainer>
      <AddEditSelfAndSpouseSection isOldEmployee={isOldEmployee} />
      <AddEditChildsSection isOldEmployee={isOldEmployee}/>
      <AddEditParentsSection isOldEmployee={isOldEmployee}/>
      <AddDependantModal isOldEmployee={isOldEmployee}/>
      <EndoAddDeleteDependantModal />
    </StyledDependantContainer>
  );
};

export default AddDependantDetails;
