import { Form, Typography, CardIcon } from '@loophealth/loop-ui-web-library';
import {
  StyledChoosePolicyItemContainer,
  StyledChooseSlabSection,
  StyledDot,
  StyledHorizontalLine,
  StyledPolicyCheckbox,
  StyledPolicyData,
  StyledPolicyDetails,
  StyledPolicyDetailsContainer,
  StyledPolicyDetailsHeader,
  StyledPolicyType,
} from './styles';
import { IChoosePolicyItemProps } from './types';
import { ChangeEvent, useEffect, useState } from 'react';
import { mapToPolicyTypeFullForm } from '../../../../utils/common/mapping';
import { converPolicySlabsIntoDropdownItems } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddSelectedPolicyDetails,
  setAddEmployeeErrors,
  setAddEmployeeIdErrors,
  setAddSelectedPolicies,
} from '../../../../redux/slices/singleAddSlice';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';

const ChoosePolicyItem: React.FC<IChoosePolicyItemProps> = ({
  policy,
  isDisabled,
  isOldEmployee,
}) => {
  const dispatch = useDispatch();
  const trackClick = useSegment('click');
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const [isChecked, setIsChecked] = useState(!!selectedPolicies[policy.id]);
  const [selectedSlab, setSelectedSlab] = useState<string>(
    selectedPolicies[policy.id]?.slabId ?? '',
  );

  const slabDropdownItems = converPolicySlabsIntoDropdownItems(
    policy.sumAssuredSlabs,
  );
  const showDropdown = isChecked && slabDropdownItems.length > 1;

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(isOldEmployee)
      trackClick(trackTaskEvent(`Select_Checkbox_${policy.policyType}_Policy_Selection_Modal_Dependant_Single_Add`));
    else
      trackClick(trackTaskEvent(`Select_Checkbox_${policy.policyType}_Policy_Selection_Modal_Single_Add`));
    setIsChecked(e.target.checked);
  }

  useEffect(() => {
    if (isChecked) {
      if (selectedSlab) {
        const slab = policy.sumAssuredSlabs?.find(
          (slab) => slab.slabId === selectedSlab,
        );
        dispatch(
          setAddSelectedPolicies({
            ...selectedPolicies,
            [policy.id]: {
              slabId: selectedSlab,
              policyId: policy.id,
              policyType: policy.policyType,
              isParentalPolicy: policy.isParentalPolicy,
              isOPDPolicy: policy.isOPDPolicy,
              sumInsuredStructure: policy.sumInsuredStructure,
              sumInsured: slab?.sumInsured,
              grade: slab?.grade,
              slabFamilyDefinition: slab?.familyDefinition,
            },
          }),
        );
        dispatch(setAddEmployeeErrors({}));
        dispatch(setAddEmployeeIdErrors(null));
      }
    } else {
      const selected = structuredClone(selectedPolicies);
      if (selected[policy.id]) {
        delete selected[policy.id];
        dispatch(setAddSelectedPolicies(selected));
        dispatch(setAddEmployeeErrors({}));
        dispatch(setAddEmployeeIdErrors(null));
      }
    }
  }, [selectedSlab, isChecked, dispatch]);

  useEffect(() => {
    if (!selectedSlab && policy.sumAssuredSlabs?.length === 1) {
      setSelectedSlab(policy.sumAssuredSlabs[0].slabId);
    }
  }, [policy]);

  useEffect(() => {
    if (isDisabled) setIsChecked(false);
  }, [isDisabled]);

  return (
    <StyledChoosePolicyItemContainer $disabled={isDisabled}>
      <StyledPolicyDetailsContainer>
        <StyledPolicyCheckbox
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={isDisabled}
        />
        <StyledPolicyDetailsHeader>
          <CardIcon size="48px" imageUrl={policy.insurerLogo} />
          <StyledPolicyData>
            <StyledPolicyType>
              <Typography variant="medium" weight="semiBold">
                {mapToPolicyTypeFullForm(policy.policyType)}
                {(policy.isParentalPolicy && ' (Parental)') ||
                  (policy.isTopUpPolicy && ' (Top Up)') ||
                  (policy.isOPDPolicy && ' (OPD)')}
              </Typography>
            </StyledPolicyType>
            <StyledPolicyDetails>
              <Typography variant="small" weight="regular" color="secondary">
                {policy.insurerName}
              </Typography>
              {policy.nickName && (
                <>
                  <StyledDot />
                  <Typography
                    variant="small"
                    weight="regular"
                    color="secondary"
                  >
                    {policy.nickName}
                  </Typography>
                </>
              )}
              {policy.policyNumber && (
                <>
                  <StyledDot />
                  <Typography
                    variant="small"
                    weight="regular"
                    color="secondary"
                  >
                    Policy No: {policy.policyNumber}
                  </Typography>
                </>
              )}
            </StyledPolicyDetails>
          </StyledPolicyData>
        </StyledPolicyDetailsHeader>
      </StyledPolicyDetailsContainer>
      {showDropdown && (
        <>
          <StyledHorizontalLine />
          <StyledChooseSlabSection>
            <Typography variant="small">Choose Policy Slab</Typography>
            <Form.Dropdown
              placeholder="Select Policy Slab"
              selectedItem={selectedSlab}
              listItems={slabDropdownItems}
              onClick={setSelectedSlab}
            />
          </StyledChooseSlabSection>
        </>
      )}
    </StyledChoosePolicyItemContainer>
  );
};

export default ChoosePolicyItem;
