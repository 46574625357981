import React, { useEffect } from 'react';
import {
  StyledDropdownEmptyStateWrapper,
  StyledNoSearchResultsImage,
} from './styles';
import {
  NoSearchResultsDeleteImage,
  NoSearchResultsImage,
} from '../../../../assets/images';
import { IDropdownEmptyState } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../utils/segment/utils';

const DropdownEmptyState = ({
  searchText,
  showSearchText,
  mode,
}: IDropdownEmptyState) => {
  const trackTask = useSegment('track');

  useEffect(() => {
    if (mode === 'SINGLE_DELETE')
      trackTask(
        trackTaskEvent('Empty_Search_Result_Member_Search_Modal_single_del'),
      );
    else if(mode==='SINGLE_ADD')
      trackTask(
        trackTaskEvent('Load_Empty_Search_Result_Member_Search_Modal_Dependant_Single_Add'),
      );
  }, []);

  return (
    <StyledDropdownEmptyStateWrapper>
      {showSearchText && (
        <Typography variant="small" color="secondary">
          Showing results for{' '}
          <Typography variant="small" weight="medium">
            "{searchText}"
          </Typography>
        </Typography>
      )}
      <StyledNoSearchResultsImage
        src={
          mode === 'SINGLE_DELETE'
            ? NoSearchResultsDeleteImage
            : NoSearchResultsImage
        }
      />
    </StyledDropdownEmptyStateWrapper>
  );
};

export default DropdownEmptyState;
