import { Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { CrossOutlineDarkIcon } from '../../../assets/images';
import { StyledCrossIcon } from '../EndoAddEditDeleteModal/styles';
import {
  StyledSubmitButtonHeader,
  StyledSubmitButtonPopup,
  StyledSubmitButtonPopupBeak,
  StyledSubmitButtonPopupContent,
  StyledSubmitButtonPopupWrapper,
} from './styles';
import { ISubmitButtonPopup } from './types';
import WithThemeProvider from '../../../theme/WithThemeProvider';

const SubmitButtonPopup: React.FC<ISubmitButtonPopup> = ({
  show,
  onClose,
  children,
}) => {
  if (!show) return <>{children}</>;
  return (
    <WithThemeProvider>
      <StyledSubmitButtonPopupWrapper>
        <StyledSubmitButtonPopup>
          <StyledSubmitButtonPopupContent>
            <StyledSubmitButtonHeader>
              <Typography variant="small" weight="bold">
                Submit Your Endo!👇🏻
              </Typography>
              <StyledCrossIcon src={CrossOutlineDarkIcon} onClick={onClose} />
            </StyledSubmitButtonHeader>
            <Typography variant="extraSmall">
              If everything looks good click below to submit your endo!
            </Typography>
            <StyledSubmitButtonPopupBeak />
          </StyledSubmitButtonPopupContent>
        </StyledSubmitButtonPopup>
        {children}
      </StyledSubmitButtonPopupWrapper>
    </WithThemeProvider>
  );
};

export default SubmitButtonPopup;
