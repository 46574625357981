import { Form, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledRadioButtonContainer,
  StyledSectionCardContainer,
  StyledSectionContainer,
  StyledSectionLabel,
} from '../styles';
import EmptyDependantCard from '../../EmptyDependantCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDeletingDependantId,
  selectIsMidtermFlow,
  setSelectedDependant,
} from '../../../../../redux/slices/singleAddSlice';
import { useDependantsHelper } from '../useDependantsHelper';
import { PARENTS_RADIO_BUTTONS, PARENTS_TYPE } from '../constants';
import { useEffect, useMemo, useState } from 'react';
import { IAddDependantDetails, IDependantUIMappingData } from '../types';
import FilledDependantCard from '../../FilledDependantCard';
import { generateLabels } from '../../AddDependantModal/utils';
import { ADD_PARENT_ERROR_TYPE, parentsErrorMessageMap } from './constants';
import AddAlertError from '../../AddAlertError';
import { trackClickEvent } from '../../../../../utils/segment/utils';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { trackDependentOpeningModal } from '../utils';

const AddEditParentsSection: React.FC<IAddDependantDetails> = ({
  isOldEmployee,
}) => {
  const trackClick = useSegment('click');
  const { dependantsUIMapping, parentsType, activeDependants } =
    useDependantsHelper();
  const dispatch = useDispatch();
  const isMidterm = useSelector(selectIsMidtermFlow);
  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [errorType, setErrorType] = useState<ADD_PARENT_ERROR_TYPE | null>(
    null,
  );
  const [selectedParentsType, setSelectedParentsType] = useState({
    id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS,
    value: 'Insure Parents',
  });
  const deletingDependantId = useSelector(selectDeletingDependantId);

  const hasParents = useMemo(() => {
    return activeDependants.filter(
      (dependant) => dependant.relationship === 'parent',
    ).length;
  }, [activeDependants]);

  const hasParentsInLaw = useMemo(() => {
    return activeDependants.filter(
      (dependant) => dependant.relationship === 'parent-in-law',
    ).length;
  }, [activeDependants]);

  useEffect(() => {
    // if parents in law are added then select parents in law radio button
    if (hasParentsInLaw && !hasParents && showRadioButtons) {
      setSelectedParentsType({
        id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW,
        value: 'Insure Parents-in-law',
      });
    }
  }, [activeDependants, hasParents, hasParentsInLaw, showRadioButtons]);

  useEffect(() => {
    if (!deletingDependantId) {
      setErrorType(null);
    }
  }, [deletingDependantId]);

  useEffect(() => {
    setErrorType(null);
  }, [dependantsUIMapping]);

  const openDependantModal = (relationship: string) => {
    trackClick(trackDependentOpeningModal(relationship, isOldEmployee));
    dispatch(setSelectedDependant(relationship));
  };

  const handleRadionButtonChange = useMemo(
    () => (radioButton: { id: PARENTS_RADIO_BUTTONS; value: string }) => {
      if (parentsType === PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW) {
        if (
          (selectedParentsType.id === PARENTS_RADIO_BUTTONS.INSURE_PARENTS &&
            hasParents) ||
          (selectedParentsType.id ===
            PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW &&
            hasParentsInLaw)
        ) {
          if (!isMidterm)
            setErrorType(ADD_PARENT_ERROR_TYPE.ONE_SIDE_INSURABLE);
          return;
        }
      }
      setSelectedParentsType(radioButton);
    },
    [
      parentsType,
      selectedParentsType.id,
      hasParents,
      hasParentsInLaw,
      isMidterm,
    ],
  );

  const totalAllowedDeps = useMemo(() => {
    switch (parentsType) {
      case PARENTS_TYPE.PARENTS_AND_PARENTS_IN_LAW:
        return 4;
      case PARENTS_TYPE.PARENTS_INCLUDING_PARENTS_IN_LAW:
        return 2;
      case PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW:
        return 2;
      default:
        return 2;
    }
  }, [parentsType]);

  const pageData = useMemo(() => {
    if (!parentsType) {
      return [];
    }
    let data: IDependantUIMappingData[] = [];
    if (PARENTS_TYPE.PARENTS_AND_PARENTS_IN_LAW === parentsType) {
      data.push(dependantsUIMapping['parents']);
      data.push(dependantsUIMapping['parentsInLaw']);
    } else if (PARENTS_TYPE.PARENTS_INCLUDING_PARENTS_IN_LAW === parentsType) {
      data.push({
        ...dependantsUIMapping['parentsIncludingParentsInLaw'],
        dependants: dependantsUIMapping['parents'].dependants,
      });
      data.push({ ...dependantsUIMapping['parentsInLaw'], sectionTitle: '' });
    } else if (parentsType === PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW) {
      setShowRadioButtons(true);
      data.push(
        selectedParentsType.id === PARENTS_RADIO_BUTTONS.INSURE_PARENTS
          ? {
              ...dependantsUIMapping['parentsOrParentsInLaw'],
              dependants: dependantsUIMapping['parents'].dependants,
            }
          : {
              ...dependantsUIMapping['parentsOrParentsInLaw'],
              dependants: dependantsUIMapping['parentsInLaw'].dependants,
            },
      );
    } else {
      data.push(dependantsUIMapping['parents']);
    }
    return data;
  }, [parentsType, dependantsUIMapping, selectedParentsType]);

  if (!parentsType || (isMidterm && !hasParents && !hasParentsInLaw)) {
    return null;
  }

  const totalAddedDeps = pageData.reduce(
    (acc, d) => acc + d.dependants.filter((f) => f.firstName).length,
    0,
  );
  const disableAdding = totalAddedDeps >= totalAllowedDeps;

  const errorMessage = parentsErrorMessageMap[selectedParentsType.id];

  return (
    <>
      {pageData?.map((data: IDependantUIMappingData) =>
        isMidterm &&
        !data.dependants.filter((dependant) => dependant.firstName)
          .length ? null : (
          <StyledSectionContainer key={data.sectionTitle}>
            {data.sectionTitle && (
              <StyledSectionLabel>
                <Typography variant="small" weight="semiBold">
                  {data.sectionTitle}{' '}
                  {data.sectionSubTitle && (
                    <Typography variant="small" weight="regular">
                      {data.sectionSubTitle}
                    </Typography>
                  )}
                </Typography>
              </StyledSectionLabel>
            )}
            {showRadioButtons && (
              <>
                <StyledRadioButtonContainer>
                  <Form.RadioButton
                    radioItems={[
                      {
                        id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS,
                        value: 'Insure Parents',
                      },
                      {
                        id: PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW,
                        value: 'Insure Parents-in-law',
                      },
                    ]}
                    selectedItem={selectedParentsType}
                    onClick={handleRadionButtonChange}
                  />
                </StyledRadioButtonContainer>
                {errorType === ADD_PARENT_ERROR_TYPE.ONE_SIDE_INSURABLE && (
                  <AddAlertError errors={errorMessage} />
                )}
              </>
            )}
            <StyledSectionCardContainer>
              {data.dependants.map((dependant, index) =>
                dependant.firstName ? (
                  <FilledDependantCard
                    key={index}
                    dependant={dependant}
                    disabled={isMidterm || dependant.isAlreadyEnrolled}
                  />
                ) : !isMidterm ? (
                  <EmptyDependantCard
                    key={index}
                    label={generateLabels(dependant._id || '').cardButtonText}
                    onClick={() => openDependantModal(dependant._id || '')}
                    disabled={disableAdding}
                  />
                ) : null,
              )}
            </StyledSectionCardContainer>
          </StyledSectionContainer>
        ),
      )}
    </>
  );
};

export default AddEditParentsSection;
