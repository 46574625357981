import { Typography } from '@loophealth/loop-ui-web-library';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import { StyledEmptyDependantCard } from './styles';
import { IEmptyDependantCardProps } from './types';
import { ADD_FORM_SUBMISSION_STATUS } from '../../../../redux/slices/singleAddSlice/types';
import { selectFormSubmissionStatus } from '../../../../redux/slices/singleAddSlice';
import { useSelector } from 'react-redux';
import theme from '../../../../theme';

const EmptyDependantCard: React.FC<IEmptyDependantCardProps> = ({
  label,
  onClick,
  disabled
}) => {
  const formSubmissionStatus = useSelector(selectFormSubmissionStatus);
  const isFormSubmitting =
    formSubmissionStatus !== ADD_FORM_SUBMISSION_STATUS.IDLE;
  const handleOnClick = () => {
    if (isFormSubmitting) {
      return;
    }
    onClick();
  };
  return (
    <StyledEmptyDependantCard onClick={handleOnClick} $disabled={disabled}>
      <AddIcon strokeColor={disabled ? theme.colors.lightGrey : theme.colors.loopEmerald}/>{' '}
      <Typography variant="small" color={disabled ? "secondary" : "emerald"} weight="semiBold">
        {label}
      </Typography>
    </StyledEmptyDependantCard>
  );
};

export default EmptyDependantCard;
