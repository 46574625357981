export interface ISegmentAct {
  name: string;
  properties?: Record<string, unknown>;
  login_status?: boolean;
  description?: string;
  page_title?: string;
}

interface ISegmentActions {
  PAGE: Record<string, ISegmentAct>;
  CLICK: Record<string, ISegmentAct>;
  IDENTIFY: Record<string, ISegmentAct>;
  TASK: Record<string, Record<string, unknown>>;
}

export const SEGMENT_ACTIONS: ISegmentActions = {
  PAGE: {
    LOGIN_PAGE: {
      name: 'Login',
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'Landed on the HR dashboard login page',
        login_status: false,
      },
    },
    OTP_PAGE: {
      name: 'Enter_Otp',
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'User lands on enter otp page',
        login_status: false,
      },
    },
    CLAIMS_LISTING_PAGE: {
      name: 'Claims',
      properties: {
        page_category: 'HRD Claims Listing Page',
        description: 'Landed on the HRD Claims listing Page. ',
        login_status: true,
      },
    },
    EMPLOYEE_LISTING_PAGE: {
      name: 'Employee_Listing',
      properties: {
        page_category: 'HRD Employee Page',
        description: 'Landed on the HR employees listing page',
        login_status: true,
      },
    },
    EMPLOYEE_DETAILS_PAGE: {
      name: 'Employee_Details',
      properties: {
        page_category: 'HRD Employee Details Page',
        description: 'Landed on the HR employee details page',
        login_status: true,
      },
    },
    CLAIM_ANALYTICS_PAGE: {
      name: 'Claim_Analytics',
      properties: {
        page_category: 'HRD Claim Analytics Page',
        description: 'Landed on the Claim Analytics page',
        login_status: true,
      },
    },
    CD_ACCOUNTS_PAGE: {
      name: 'Cd_Accounts',
      properties: {
        page_category: 'HRD CD Accounts Page',
        description: 'Landed on the CD Accounts page',
        login_status: true,
      },
    },
    ENDORSEMENTS_PAGE: {
      name: 'Endorsements',
      properties: {
        page_category: 'HRD Endorsements Page',
        description: 'Landed on the Endorsements page',
        login_status: true,
      },
    },
    ENDORSEMENT_DETAILS_PAGE: {
      name: 'Endorsement Details',
      properties: {
        page_category: 'HRD Endorsement Details Page',
        description: 'Landed on the Endorsement Details page',
        login_status: true,
      },
    },
    BULK_ADD_PAGE: {
      name: 'Bulk Add',
      properties: {
        page_category: 'HRD Bulk Add Page',
        description: 'Landed on the Bulk Add page',
        login_status: true,
      },
    },
    BULK_DELETE_PAGE: {
      name: 'Bulk Delete',
      properties: {
        page_category: 'HRD Bulk Delete Page',
        description: 'Landed on the Bulk Delete page',
        login_status: true,
      },
    },
    POLICY_LISTING_PAGE: {
      name: 'Policies',
      properties: {
        page_category: 'HRD Policy Page',
        description: 'Landed on the HR policies page',
        login_status: true,
      },
    },
    POLICY_DETAILS_PAGE: {
      name: 'Policy Details',
      properties: {
        page_category: 'HRD Policy Details Page',
        description: 'Landed on the HR policy details page',
        login_status: true,
      },
    },
    MIDTERM_FOUND_MODAL_ADD: {
      name: 'MIDTERM_FOUND_MODAL_ADD',
      properties: {
        page_category: 'Midterm Found Modals',
        description: 'Landed on the Midterm Modal',
        login_status: true,
      },
    },
    REVIEW_UPLOADED_DATA_ADD: {
      name: 'REVIEW_UPLOADED_DATA_ADD',
      properties: {
        page_category: 'Review Uploaded Details Page',
        description: 'Landed on the Review Uploaded Details',
        login_status: true,
      },
    },
    UPLOAD_UNSUCCESSFUL_ADD: {
      name: 'UPLOAD_UNSUCCESSFUL_ADD',
      properties: {
        page_category: 'Midterm Unsuccessful Add Records',
        description: 'Landed on the Midterm Unsuccesful Add Records Details',
        login_status: true,
      },
    },
    UPLOAD_SUCCESSFUL_ADD: {
      name: 'UPLOAD_SUCCESSFUL_ADD',
      properties: {
        page_category: 'Midterm Successful Add Records',
        description: 'Landed on the Midterm Successful Add Records Details',
        login_status: true,
      },
    },
    BULK_EDIT_PAGE: {
      name: 'Bulk_Edit_Page_edit',
      properties: {
        page_category: 'Bulk Edit Page',
        description: 'Landed on the Bulk Edit Page',
        login_status: true,
      },
    },
    SINGLE_ADD_MEMBERS_PAGE: {
      name: 'Single_Add_Members_Page',
      properties: {
        page_category: 'Single Add Members Page',
        description: 'Landed on the Single Add Members Page',
        login_status: true,
      },
    },
    MEMBER_SEARCH_MODAL_SCREEN_EDIT: {
      name: 'Member_Search_Modal_Screen_edit',
      properties: {
        page_category: 'Member Search Modal',
        description: 'Landed on the Member Search Modal Screen',
        login_status: true,
      },
    },
    EMPTY_EDIT_SEARCH_RESULT_PAGE: {
      name: 'Empty_Search_Result_Member_Search_Modal_edit',
      properties: {
        page_category: 'Empty Edit Search Result Page',
        description: 'Landed on the Empty Edit Search Result Page',
        login_status: true,
      },
    },
    MEMBER_EDIT_MODAL_SCREEN_EDIT: {
      name: 'Member_Edit_Modal_Screen_edit',
      properties: {
        page_category: 'Member Edit Modal Screen',
        description: 'Landed on the Member Edit Modal Screen',
        login_status: true,
      },
    },
    VIEW_COST_ESTIMATES_EDIT: {
      name: 'View_Cost_Estimates_edit',
      properties: {
        page_category: 'View Cost Estimates',
        description: 'Landed on the View Cost Estimates Page',
        login_status: true,
      },
    },
  },
  CLICK: {
    POLICY_DETAILS: {
      name: 'POLICY_DETAILS',
      properties: {
        describe: 'User Clicked on PolicyDetails Page',
        page_category: '',
        login_status: true,
      },
    },
    OUTLOOK_LOGIN: {
      name: 'outlook_login',
      description: 'User Clicks on OutlookLogin',
      page_title: 'login',
      login_status: false,
    },
    GOOGLE_LOGIN: {
      name: 'google_login',
      description: 'User Clicks on GoogleLogin',
      page_title: 'login',
      login_status: false,
    },
    MOBILE_LOGIN: {
      name: 'mobile_login',
      description: 'User Clicks on GetOTP',
      page_title: 'login',
      login_status: false,
    },
    VERIFY_OTP: {
      name: 'verifyOtp',
      page_title: 'enterOtp',
      description: 'User Clicks on verify OTP',
      login_status: false,
    },
    RESEND_OTP: {
      name: 'resendOtp',
      page_title: 'enterOtp',
      description: 'User Clicks on resend OTP',
      login_status: false,
    },
    LOOP_LOGO: {
      name: 'loop_logo',
      description: 'User Clicks on LoopIcon',
      login_status: true,
    },
    LOGOUT: {
      name: 'logout',
      description: 'User Clicks on Logout btn',
      login_status: true,
    },
    GIVE_FEEDBACK: {
      name: 'give_feedback',
      page_title: 'GiveFeedback',
      description: 'Give Feedback Button Clicked',
      login_status: true,
    },
    Action_Bulk_Edit_Page_edit: {
      name: 'action_bulk_edit_page_edit',
      page_title: 'Bulk Edit Page',
      description: 'User Clicks on action button',
      login_status: true,
    },
    Select_Members_Bulk_Edit_Page_edit: {
      name: 'select_members_bulk_edit_page_edit',
      page_title: 'Bulk Edit Page',
      description: 'User Clicks on select members button',
      login_status: true,
    },
    Back_Members_Bulk_Edit_Page_edit: {
      name: 'back_members_bulk_edit_page_edit',
      page_title: 'Bulk Edit Page',
      description: 'User Clicks on back button',
      login_status: true,
    },
    Search_Bar_Member_Search_Modal_edit: {
      name: 'search_bar_member_search_modal_edit',
      page_title: 'Bulk Edit Page',
      description: 'User Clicks on search bar',
      login_status: true,
    },
    Search_Result_Member_Search_Modal_edit: {
      name: 'search_result_member_search_modal_edit',
      page_title: 'Member Search Modal',
      description: 'User Clicks on search result',
      login_status: true,
    },
    Empty_Search_Result_Member_Search_Modal_edit: {
      name: 'empty_search_result_member_search_modal_edit',
      page_title: 'Member Search Modal',
      description: 'User Clicks on empty search result',
      login_status: true,
    },
    Edit_Search_Results_Member_Search_Modal_edit: {
      name: 'edit_search_results_member_search_modal_edit',
      page_title: 'Member Search Modal',
      description: 'User Clicks on edit search results',
      login_status: true,
    },
    Cancel_Member_Search_Modal_edit: {
      name: 'cancel_member_search_modal_edit',
      page_title: 'Member Search Modal',
      description: 'User Clicks on cancel button',
      login_status: true,
    },
    Back_Member_Edit_Modal_Screen_edit: {
      name: 'back_member_edit_modal_screen_edit',
      page_title: 'Member Edit Modal',
      description: 'User Clicks on back button',
      login_status: true,
    },
    Edit_Field_Member_Edit_Modal_Screen_edit: {
      name: 'edit_field_member_edit_modal_screen_edit',
      page_title: 'Member Edit Modal',
      description: 'User Clicks on edit field',
      login_status: true,
    },
    Save_Field_Member_Edit_Modal_Screen_edit: {
      name: 'save_field_member_edit_modal_screen_edit',
      page_title: 'Member Edit Modal',
      description: 'User Clicks on save field',
      login_status: true,
    },
    Bin_Review_Member_Edit_Modal_Screen_edit: {
      name: 'bin_review_member_edit_modal_screen_edit',
      page_title: 'Member Edit Modal',
      description: 'User Clicks on bin review',
      login_status: true,
    },
    Submit_Member_Edit_Modal_Screen_edit: {
      name: 'submit_member_edit_modal_screen_edit',
      page_title: 'Member Edit Modal',
      description: 'User Clicks on submit',
      login_status: true,
    },
    View_Cost_Bulk_Edit_Page_edit: {
      name: 'view_cost_bulk_edit_page_edit',
      page_title: 'Bulk Edit Page',
      description: 'User Clicks on view cost',
      login_status: true,
    },
    Review_Again_View_Cost_Estimates_edit: {
      name: 'review_again_view_cost_estimates_edit',
      page_title: 'View Cost Estimates',
      description: 'User Clicks on review again',
      login_status: true,
    },
    Submit_Member_View_Cost_Estimates_edit: {
      name: 'submit_member_view_cost_estimates_edit',
      page_title: 'View Cost Estimates',
      description: 'User Clicks on submit',
      login_status: true,
    },
    Back_View_Cost_Estimates_edit: {
      name: 'back_view_cost_estimates_edit',
      page_title: 'View Cost Estimates',
      description: 'User Clicks on back',
      login_status: true,
    },
  },
  TASK: {
    LOGOUT_SUCCESS: {
      task: 'logout',
      login_status: false,
      status: true,
    },
    LOGOUT_FAIL: {
      task: 'logout',
      login_status: true,
      status: false,
    },
    CLEAR_LOGIN_DATA: {
      task: 'clear_login',
      login_status: false,
      status: true,
    },
    OUTLOOK_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: true,
      status: true,
    },
    OUTLOOK_LOGIN_FAIL: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: false,
      status: false,
    },
    GOOGLE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: true,
      status: true,
    },
    GOOGLE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_FAIL: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_SUCCESS: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_OTP_FAIL: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_OTP_SUCCESS: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: true,
      status: true,
    },
    MOBILE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_SUCCESS: {
      task: 'user_updated',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_FAIL: {
      task: 'user_updated',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_SUCCESS: {
      task: 'user_added',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_FAIL: {
      task: 'user_added',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_SUCCESS: {
      task: 'dependent_updated',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_FAIL: {
      task: 'dependent_updated',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_SUCCESS: {
      task: 'employee_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_FAIL: {
      task: 'employee_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_SUCCESS: {
      task: 'dependent_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_FAIL: {
      task: 'dependent_deleted',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_SUCCESS: {
      task: 'employee_ecard_downloaded',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_FAIL: {
      task: 'employee_ecard_downloaded',
      login_status: true,
      status: false,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_SUCCESS: {
      task: 'dependent_ecard_downloaded',
      login_status: true,
      status: true,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_FAIL: {
      task: 'dependent_ecard_downloaded',
      login_status: true,
      status: false,
    },
    Load_Incorrect_Format_Banner_add: {
      task: "Load 'Upload file format is tempered' banner",
      page_category: 'Upload Addition Data',
      login_status: true,
      status: false,
    },
    Load_Incorrect_Format_Banner_delete: {
      task: "Load 'Upload file format is tempered' banner",
      page_category: 'Upload Deletion Data',
      login_status: true,
    },
    Load_Incorrect_File_Size_Banner_add: {
      task: "Load 'File size should not be more than...' banner",
      page_category: 'Upload Addition Data',
      login_status: true,
    },
    Load_Incorrect_File_Size_Banner_delete: {
      task: "Load 'File size should not be more than...' banner",
      page_category: 'Upload Deletion Data',
      login_status: true,
    },
    Load_Upload_Proceed_Button_add: {
      task: 'User succesfully uploads a file',
      page_category: 'Upload Addition Data',
      login_status: true,
    },
    Load_Upload_Proceed_Button_delete: {
      task: 'User succesfully uploads a file',
      page_category: 'Upload Deletion Data',
      login_status: true,
    },
    Load_Incorrect_Format_Midterm_add: {
      task: "Load 'Upload file format is not supported...' banner",
      page_category: 'Review Uploaded Data - Midterm',
      login_status: true,
    },
    Load_Incorrect_File_Size_Midterm_add: {
      task: "Load 'File size should not be more than...' banner",
      page_category: 'Review Uploaded Data - Midterm',
      login_status: true,
    },
    Load_Error_Table_add: {
      task: 'Load error table page',
      page_category: 'Review Uploaded Data',
      login_status: true,
    },
    Add_Validation_Summary: {
      task: 'Add Validation Summary',
      page_category: 'Review Uploaded Data',
      login_status: true,
    },
    Delete_Validation_Summary: {
      task: 'Delete Validation Summary',
      page_category: 'Review Uploaded Data',
      login_status: true,
    },
    Load_Error_Table_delete: {
      task: 'Load error table page',
      page_category: 'Review Uploaded Data',
      login_status: true,
    },
    Load_EndoBlocked_LowCD_track: {
      task: "Load 'Endorsement blocked due to low CD balance...'  message",
      page_category: 'Endo Bucket',
      login_status: true,
    },
    Load_Add_Edit_Delete_Modal_Employee: {
      task: 'Load Add/Edit/Delete modal',
      page_category: 'Employees',
      login_status: true,
    },
    Load_Add_Edit_Delete_Modal_Endorsements: {
      task: 'Load Add/Edit/Delete modal',
      page_category: 'Endorsements',
      login_status: true,
    },
    Load_MultP_MultCD_Suff_add: {
      task: 'Load Multiple Policy with Multiple CD (Sufficient)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_MultP_SingleCD_Suff_add: {
      task: 'Load Multiple Policy with Single CD (Sufficient)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_MultP_SameCD_Suff_add: {
      task: 'Load Multiple Policy with Same or diff CD (Sufficient)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_SingleP_SingleCD_Suff_add: {
      task: 'Load Single Policy with Single CD (Sufficient)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_MultP_MultCD_Low_add: {
      task: 'Load Multiple Policy with Multiple CD (Low)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_MultP_SingleCD_Low_add: {
      task: 'Load Multiple Policy with Single CD (Low)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_MultP_SameCD_Low_add: {
      task: 'Load Multiple Policy with same or diff CD (Low)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    Load_SingleP_SingleCD_Low_add: {
      task: 'Load Single Policy with Single CD (Sufficient)',
      page_category: 'Submit Addition Request',
      login_status: true,
    },
    PULSE_SCORE: {
      task: 'pulse_score',
      page_category: 'Pulse Score',
      login_status: true,
    },
    ENDORSEMENTS_VISIBILITY: {
      task: 'Endorsements tab is visible or not',
      page_category: 'HRD ENDO SELF SERVE',
      login_status: true,
    },
  },
  IDENTIFY: {
    USER_LOGIN: {
      name: 'USER_LOGIN',
      properties: {
        user_id: '',
      },
    },
    USER_LOGOUT: { name: 'USER_LOGOUT' },
  },
};

export const TaskByLoginMethod = {
  google: 'GOOGLE_LOGIN',
  outlook: 'OUTLOOK_LOGIN',
  mobile: 'MOBILE_LOGIN',
};