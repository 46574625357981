import { ILoginMethod } from '../../redux/slices/usersSlice/types';
import { SEGMENT_ACTIONS, TaskByLoginMethod } from './SegmentActionConstants';

export const getSegmentPageName = (pathName: string): string => {
  const routeName = pathName?.split('/');
  switch (routeName?.[1] || routeName?.[0]) {
    case 'login':
      return 'LOGIN_PAGE';
    case 'enter-otp':
      return 'OTP_PAGE';
    case 'employees':
      return 'EMPLOYEE_LISTING_PAGE';
    case 'employee-details':
      return 'EMPLOYEE_DETAILS_PAGE';
    case 'policies':
      return 'POLICY_LISTING_PAGE';
    case 'policy-details':
      return 'POLICY_DETAILS_PAGE';
    case 'claims':
      return 'CLAIMS_LISTING_PAGE';
    case 'claim-analytics':
      return 'CLAIM_ANALYTICS_PAGE';
    case 'cd-accounts':
      return 'CD_ACCOUNTS_PAGE';
    case 'endorsements':
      return 'ENDORSEMENTS_PAGE';
    case 'endorsement-details':
      return 'ENDORSEMENT_DETAILS_PAGE';
    case 'bulk-add':
      return 'BULK_ADD_PAGE';
    case 'bulk-delete':
      return 'BULK_DELETE_PAGE';
    case 'bulk-add-errors':
      return 'REVIEW_UPLOADED_DATA_ADD';
    case 'bulk-delete-errors':
      return 'UPLOAD_DELETION_DATA_DEL';
    case 'midterm-found-modal-add':
      return 'MIDTERM_FOUND_MODAL_ADD';
    case 'upload-unsuccessful-add':
      return 'UPLOAD_UNSUCCESSFUL_ADD';
    case 'upload-successful-add':
      return 'UPLOAD_SUCCESSFUL_ADD';
    case 'bulk-edit':
      return 'BULK_EDIT_PAGE';
    case 'single-add':
      return 'SINGLE_ADD_MEMBERS_PAGE';
    case 'member-search-page':
      return 'MEMBER_SEARCH_MODAL_SCREEN_EDIT';
    case 'empty-edit-search-result-page':
      return 'EMPTY_EDIT_SEARCH_RESULT_PAGE';
    case 'member-edit-page':
      return 'MEMBER_EDIT_MODAL_SCREEN_EDIT';
    case 'view-cost-estimates-edit':
      return 'VIEW_COST_ESTIMATES_EDIT';
    default:
      return 'UNKNOWN_PAGE';
  }
};

export const extractPathName = (pathname: string): string => {
  return pathname.slice(1).replace(/[/-]/g, '_').toUpperCase();
};

export const getTaskByLoginMethod = (
  loginMethod: ILoginMethod,
  status: boolean,
) =>
  TaskByLoginMethod[loginMethod]
    ? TaskByLoginMethod[loginMethod] + `${status ? '_SUCCESS' : '_FAIL'}`
    : 'UNKNOWN';

export const trackPageEvent = (
  pathname: string = '',
  pageProperties?: Record<string, unknown>,
): { name: string; properties?: Record<string, unknown> | undefined } => {
  const page_id = getSegmentPageName(pathname);
  const currentPageAction = SEGMENT_ACTIONS.PAGE[page_id];
  return {
    name: currentPageAction?.name ?? page_id,
    properties: {
      ...(currentPageAction?.properties ?? {}),
      ...pageProperties,
      page_url: pathname,
      page_id,
    },
  };
};

export const trackClickEvent = (
  action: string,
  pathname: string | null = '',
  page_category = '',
  otherProperties: Record<string, unknown> = {},
) => {
  const currentPageAction = SEGMENT_ACTIONS.CLICK[action];
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return {
    name: action,
    properties: {
      page_category,
      page_title,
      ...otherProperties,
      ...(currentPageAction || {}),
    },
  };
};

export const trackTaskEvent = (
  action: string,
  pathname: string | null = '',
  page_category = '',
  otherProperties: Record<string, unknown> = {},
) => {
  const currentPageAction = SEGMENT_ACTIONS.TASK[action];
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return {
    name: action,
    properties: {
      page_category,
      page_title,
      ...currentPageAction,
      ...otherProperties,
    },
  };
};
